export enum QUERIES_KEYS {
  GET_USER_BY_ID = 'GET_USER_BY_ID',
  GET_APP_FEATURE_FLAGS = 'GET_APP_FEATURE_FLAGS',
  GET_USERS = 'GET_USERS',
  GET_ACCESS_GROUPS = 'GET_ACCESS_GROUPS',
  GET_APP_CONFIG = 'GET_APP_CONFIG',
  CURRENT_USER = 'CURRENT_USER',
  COMPANY_ID = 'COMPANY_ID',
  COMPANY_IDS = 'COMPANY_IDS',
  COMPANY_USERS = 'COMPANY_USERS',
  GET_COMPANIES = 'GET_COMPANIES',
  GET_PM_COMPANIES = 'GET_PM_COMPANIES',
  GET_COMPANIES_CUSTOM_DASHBOARDS = 'GET_COMPANIES_CUSTOM_DASHBOARDS',
  CREATE_USER = 'CREATE_USER',
  GET_COMPANIES_SITES = 'GET_COMPANIES_SITES',
  GET_SITE_WATER_EXPERTS = 'GET_SITE_WATER_EXPERTS',
  GET_COMPANY_CUSTOMER_SUCCESS = 'GET_COMPANY_CUSTOMER_SUCCESS',
  GET_COMPANY_SITE = 'GET_COMPANY_SITE',
  GET_COMPANY_SITE_RISK = 'GET_COMPANY_SITE_RISK',
  GET_COMPANIES_MODULES = 'GET_COMPANIES_MODULES',
  GET_ACCESS_GROUP = 'GET_ACCESS_GROUP',
  GET_COMPANY_ACCESS_GROUP = 'GET_COMPANY_ACCESS_GROUP',
  GET_RISK_SITE_EXECUTIONS = 'GET_RISK_SITE_EXECUTIONS',
  PROJECT_ID = 'PROJECT_ID',
  PARTNER_ID = 'PARTNER_ID',
  GET_PM_UPDATE_EXECUTIONS = 'GET_PM_UPDATE_EXECUTIONS',
  GET_COORDS_SITE_EXECUTIONS = 'GET_COORDS_SITE_EXECUTIONS',
  GET_RISK_SITE_EXECUTION_DATA = 'GET_RISK_SITE_EXECUTION_DATA',
  GET_PARTNERS = 'GET_PARTNERS',
  GET_COMPANIES_DISABLED_SITES = 'GET_COMPANIES_DISABLED_SITES',
  GET_COMPANIES_LICENCES = 'GET_COMPANIES_LICENCES',
  GET_COMPANIES_ROLE_TYPES = 'GET_COMPANIES_ROLE_TYPES',
  GET_COMPANY_INDUSTRIES = 'GET_COMPANY_INDUSTRIES',
  GET_COMPANIES_SITES_FILES = 'GET_COMPANIES_SITES_FILES',
  GET_SELF_ASSESSMENTS = 'GET_SELF_ASSESSMENTS',
  GET_FORM_ASSIGNATIONS = 'GET_FORM_ASSIGNATIONS',
  FORM_GENERATE_ASSIGNATION_SELF_ASSESSMENTS = 'FORM_GENERATE_ASSIGNATION_SELF_ASSESSMENTS',
  GET_BO_DASHBOARDS = 'GET_BO_DASHBOARDS',
  GET_BO_DASHBOARD = 'GET_BO_DASHBOARD',
  GET_BO_DASHBOARD_PROVIDER = 'GET_BO_DASHBOARD_PROVIDER',
  GET_SHAREPOINT_ACADEMY_ATTACHMENTS = 'GET_SHAREPOINT_ACADEMY_ATTACHMENTS',
  GET_SHAREPOINT_ACADEMY_ATTACHMENT_BY_ID = 'GET_SHAREPOINT_ACADEMY_ATTACHMENT_BY_ID',
  GET_SHAREPOINT_SHARED_ATTACHMENTS = 'GET_SHAREPOINT_SHARED_ATTACHMENTS',
  GET_SHAREPOINT_SHARED_ATTACHMENT_BY_ID = 'GET_SHAREPOINT_SHARED_ATTACHMENT_BY_ID',
}

export interface IJWT {
  accessToken: string;
  exp: number;
}

export interface IdentityProvider {
  identityProvider: string;
}

export interface APIError {
  data: { type: string; message: string };
  status: number;
  statusText: string;
}

export enum ErrorCodes {
  Ok = 200,
  NotFound = 404,
  Unauthorized = 401,
  UnprocessableEntity = 422,
  TooManyRequests = 429,
  InternalError = 500,
  UnderConstruction = 503,
}
